import { getNDSValue } from '@/vue_apps/FinanceModule/NDSCalculator/getNDSValue'
import { TAX_RATES, TAX_RATES_TEXTS } from '@/vue_apps/Taxes/const/const'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { STUB_NDS_TEXT_IN_ORDER } from './consts'

export const getNDSForRow = (nestedFieldSet) => {
  const finalSum = nestedFieldSet.get('final_sum')
  const taxScheme = nestedFieldSet.get('tax_scheme')

  const ndsValue = getNDSValue(finalSum, taxScheme)

  const ndsValueText = taxScheme === TAX_RATES.NDS_NONE
    ? TAX_RATES_TEXTS[TAX_RATES.NDS_NONE]
    : vueFilters.price(ndsValue)

  nestedFieldSet._ndsValue = ndsValue

  return {
    ndsValue,
    schemeTitle: TAX_RATES_TEXTS[taxScheme],
    schemeValueText: ndsValueText,
  }
}

export const getNDSForRowAsStub = (nestedFieldSet) => {
  nestedFieldSet._ndsValue = 0

  return {
    ndsValue: 0,
    schemeTitle: STUB_NDS_TEXT_IN_ORDER,
    schemeValueText: STUB_NDS_TEXT_IN_ORDER,
  }
}

export const getNDSForRowWithMemberCheck = (nestedFieldSet) => {
  return nestedFieldSet.isMember() && !gon.application.print_receipt_with_complex_members
    ? getNDSForRowAsStub(nestedFieldSet)
    : getNDSForRow(nestedFieldSet)
}
