import { getNDSForComplex } from '@/forms/components/order/NDS/getNDSForComplex'
import { getNDSForRowWithMemberCheck } from '@/forms/components/order/NDS/shared'

export const drawNDS = (nestedFieldSet) => {
  if (nestedFieldSet.isConsumable()) { return }

  const $taxSchemeContainer = nestedFieldSet.html().find('.entry-tax-scheme')
  const $taxSchemeContainerSum = nestedFieldSet.html().find('.f-order-entry-tax-scheme-sum')

  const { schemeTitle, schemeValueText } = nestedFieldSet.isComplex()
    ? getNDSForComplex(nestedFieldSet)
    : getNDSForRowWithMemberCheck(nestedFieldSet)

  $taxSchemeContainer.text(schemeTitle)
  $taxSchemeContainerSum.text(schemeValueText.replace(',', '.'))
}
