import tippy from 'tippy.js'
import { IMeasureUnitRaw } from '@/_declarations/IMeasureUnit'

export const drawOrderMeasureUnit =
  (rowHtml: HTMLElement, measureUnit: IMeasureUnitRaw) => {
    if (!rowHtml || !measureUnit) { return }

    const measureUnitCell = rowHtml.querySelector('.measure-unit')

    const measureUnitShortTitleElement = measureUnitCell.querySelector('.f-measure-unit-short-title') as HTMLInputElement
    if (!measureUnitShortTitleElement) { return }

    measureUnitShortTitleElement.innerText = measureUnit.short_title
    tippy(measureUnitCell, { content: measureUnit.title })

    const measureUnitIdElement = measureUnitCell.querySelector('input.f-measure-unit-id') as HTMLInputElement
    if (!measureUnitIdElement) { return }

    measureUnitIdElement.value = String(measureUnit.id)
  }
