import { TAX_RATES, TAX_RATES_TEXTS } from '@/vue_apps/Taxes/const/const'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { getNDSForRow } from './shared'

const getNDSTitleText = (taxSchemes, finalNdsSum) => {
  if (!finalNdsSum && taxSchemes.size === 1 && taxSchemes.has(TAX_RATES.NDS_NONE)) {
    return t('ndsnone')
  }

  return taxSchemes.size > 1
    ? t('ndsDifferent')
    : TAX_RATES_TEXTS[Array.from(taxSchemes)[0]]
}

const getNDSValueText = (taxSchemes, finalNdsSum) => {
  if (taxSchemes.size === 1 && taxSchemes.has(TAX_RATES.NDS_NONE)) {
    return t('ndsnone')
  }

  return vueFilters.price(finalNdsSum)
}

const getNDSForComplexByMembers = (nestedFieldSet) => {
  const taxSchemes = new Set()
  const finalNdsSum = nestedFieldSet.getMembers()
    .reduce((acc, member) => {
      if (member.get('_destroy') === 'true') {
        return acc
      }

      const taxScheme = member.get('tax_scheme')
      taxScheme && taxSchemes.add(taxScheme)

      return acc + member._ndsValue
    }, 0)

  nestedFieldSet._ndsValue = finalNdsSum

  return {
    finalNdsSum,
    schemeTitle: getNDSTitleText(taxSchemes, finalNdsSum),
    schemeValueText: getNDSValueText(taxSchemes, finalNdsSum),
  }
}

export const getNDSForComplex = (nestedFieldSet) => {
  return gon.application.print_receipt_with_complex_members
    ? getNDSForComplexByMembers(nestedFieldSet)
    : getNDSForRow(nestedFieldSet)
}
